import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import headerImage from '../assets/background.webp'; 

const Home = () => {
  const scrollToContent = () => {
   const nextSection = document.querySelector('.section'); // Der nächste Abschnitt, zu dem gescrollt werden soll
   nextSection.scrollIntoView({ behavior: 'smooth' }); // Smooth Scrolling-Effekt
  };

  return (
    <div className="home">
      <header className="home-header" style={{ backgroundImage: `url(${headerImage})` }}>
        <div className="header-overlay">
          <p>Ihr Partner für hochwertige Gipsarbeiten</p>
          <h1>Willkommen bei der GALL GU GmbH</h1>
        </div>
        {/* Scroll Icon */}
        <div className="scroll-icon" onClick={scrollToContent}>
          <span>&#x21D3;</span> {/* Unicode für ein Pfeilsymbol */}
        </div>
      </header>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6  heading-background about-us-background">
            <h2 className="section-heading">Über uns</h2>
          </div>
          <div className="col-12  col-md-6  text-box">
            <p>
              Die GALL GU GmbH ist Ihr kompetenter Partner für alle Arten von Gipsarbeiten.
              Wir bieten Ihnen professionelle Dienstleistungen mit höchsten Qualitätsstandards
              und jahrelanger Erfahrung.<br/>
              <Link to="/ueber-uns">Mehr erfahren</Link>
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6  text-box">
            <div>
                <ul>
                  <li><HashLink smooth to="/dienstleistungen#malerei">Malerei</HashLink></li>
                  <li><HashLink smooth to="/dienstleistungen#gipsarbeiten">Gipsarbeiten</HashLink></li>
                  <li><HashLink smooth to="/dienstleistungen#aussenisolationen">Aussenisolationen</HashLink></li>
                  <li><HashLink smooth to="/dienstleistungen#renovierungen">Renovierungen</HashLink></li>
                  <li><HashLink smooth to="/dienstleistungen#trockenbau">Trockenbau</HashLink></li>
                </ul>
            </div>
          </div>
          <div className="col-12  col-md-6  heading-background services-background">
            <h2 className="section-heading">Unsere Dienstleistungen</h2>
          </div>
        </div>
      </section>
      {/* <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6  heading-background testimonials-background">
            <h2 className="section-heading">Kundenbewertungen</h2>
          </div>
          <div className="col-12  col-md-6  text-box">
              <p>"Die Arbeiten wurden professionell und termingerecht ausgeführt. Sehr empfehlenswert!" - Kunde A</p>
              <p>"Top Qualität und hervorragender Service. Wir sind sehr zufrieden." - Kunde B</p>
          </div>
        </div>
      </section> */}
      {/* <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6  text-box">
            <div className="gallery-images">
              <img src="../assets/project1.jpg" alt="Projekt 1" />
              <img src="../assets/project2.jpg" alt="Projekt 2" />
              <img src="../assets/project3.jpg" alt="Projekt 3" />
            </div>
          </div>
          <div className="col-12  col-md-6  heading-background projects-background">
            <h2 className="section-heading">Unsere Projekte</h2>
          </div>
        </div>
      </section> */}
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6  heading-background partner-background">
            <h2 className="section-heading">Unsere Partner</h2>
          </div>
          <div className="col-12  col-md-6  text-box">
            <p>
            Gemeinsam stark! Entdecken Sie unsere vertrauensvollen Partner, mit denen wir erfolgreich zusammenarbeiten. Klicken Sie <Link to="/partner">hier</Link>, um mehr über unsere Partner zu erfahren und unsere zuverlässigen Kooperationen kennenzulernen.
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6  text-box">
            <p>
            Haben Sie Fragen oder möchten Sie mehr über unsere Dienstleistungen erfahren? Kontaktieren Sie uns und klicken Sie <Link to="/kontakt">hier</Link>, um mit uns in Verbindung zu treten.
            </p>
          </div>
          <div className="col-12  col-md-6  heading-background contact-background">
            <h2 className="section-heading">Kontaktieren Sie uns</h2>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
