import React from 'react';
import './Partner.css';
import { Link } from 'react-router-dom';
import headerImage from '../assets/partner-header.webp';
import geomaLogo from '../assets/partner/geoma-logo.png';

const Partner = () => {
  return (
    <div className="partner">
      <header className="header" style={{ backgroundImage: `url(${headerImage})`, backgroundPosition: '50% 20%' }}>
      </header>
      <section className="section">
        <div className="container-fluid flex-wrap">
          <div className='row justify-content-center'>
            <div className='col-12 text-box'>
              <h2>Unser Partner</h2>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className="col-12 col-md-4 heading-background">
              <Link to="https://www.geoma.ch/" target='_blank'>
                <img src={geomaLogo} alt="Geoma GmbH Logo" className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partner;
