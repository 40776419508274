import React, { useState } from 'react';
import './Contact.css';
import headerImage from '../assets/contact-header.webp';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    company: '',
    phone: '',
    email: '',
    message: '',
    captcha: '',
  });

  const [formStatus, setFormStatus] = useState(null); // Speichert den Status der Formulareinreichung

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.captcha) {
      setFormStatus({
        type: 'danger',
        message: 'Bitte füllen Sie das reCAPTCHA aus',
      });
      return;
    }

    console.log("Submitting form...");

    const response = await fetch('https://gall-gu-backend-9c590d624410.herokuapp.com/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setFormStatus({
        type: 'success',
        message: 'Nachricht erfolgreich gesendet!',
      });
      setFormData({ firstname: '', lastname: '', company: '', phone: '', email: '', message: '', captcha: '' });
      window.history.replaceState(null, null, window.location.pathname); // Reset URL to prevent re-submission
    } else {
      setFormStatus({
        type: 'danger',
        message: 'Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.',
      });
    }
  };

  const handleCaptchaChange = (value) => {
    setFormData({ ...formData, captcha: value });
  };

  return (
    <div className="contact">
      <header className="header" style={{ backgroundImage: `url(${headerImage})` }}>
      </header>
      <section id="contact" className="contact-section">
        <div className='contact-form-container'>
          <h2>Kontaktieren Sie uns</h2>

          {/* Anzeige des Formularstatus (Erfolg oder Fehler) */}
          {formStatus && (
            <div className={`alert alert-${formStatus.type}`} role="alert">
              {formStatus.message}
            </div>
          )}

          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">Vorname*</label>
                <input type="text" id="firstName" name="firstName" value={formData.firstname} onChange={(e) => setFormData({ ...formData, firstname: e.target.value })} required />
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Name*</label>
                <input type="text" id="lastName" name="lastName" value={formData.lastname} onChange={(e) => setFormData({ ...formData, lastname: e.target.value })} required />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="company">Firma</label>
                <input type="text" id="company" name="company" value={formData.company} onChange={(e) => setFormData({ ...formData, company: e.target.value })} />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Telefon*</label>
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} required />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">E-Mail*</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
            </div>

            <div className="form-group">
              <label htmlFor="message">Nachricht*</label>
              <textarea id="message" name="message" rows="5" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} required></textarea>
            </div>

            <div className="form-group">
              <ReCAPTCHA
                sitekey="6Lfv0T0qAAAAACJJYwgYhHKDZbxCvuLuqe8ecRGp"  // Replace with your Google reCAPTCHA site key
                onChange={handleCaptchaChange}
              />
            </div>

            <button type="submit">Absenden</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
