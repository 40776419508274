import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo-white.png'; // Importiere das Logo
import { FaBars, FaTimes } from 'react-icons/fa'; // Importiere Icons

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const closeMobileMenu = () => {
    if (windowWidth <= 820) {
      setIsMobile(false);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 820) {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="logo-container">
        <Link to="/" onClick={closeMobileMenu}><img src={logo} alt="GALL GU GmbH Logo" className="logo" /></Link>
      </div>
      <ul className={isMobile ? "nav-links-mobile active" : "nav-links"}>
        <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
        <li><Link to="/ueber-uns" onClick={closeMobileMenu}>Über uns</Link></li>
        <li><Link to="/dienstleistungen" onClick={closeMobileMenu}>Dienstleistungen</Link></li>
        <li><Link to="/partner" onClick={closeMobileMenu}>Partner</Link></li>
        {/* <li><Link to="/gallery" onClick={closeMobileMenu}>Galerie</Link></li> */}
        <li><Link to="/kontakt" onClick={closeMobileMenu}>Kontakt</Link></li>
      </ul>
      <div className="menu-icon" onClick={handleToggle}>
        {isMobile ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;
