import React from 'react';

const Gallery = () => {
  return (
    <section id="gallery">
      <h2>Galerie</h2>
      <p>Einige unserer besten Arbeiten.</p>
    </section>
  );
};

export default Gallery;
